import React, { useEffect, useState } from "react";
import ChatUi from "./components/pages/ChatUi/ChatUi";
import LandingPage from "./components/pages/LandingPage/LandingPage";
import { TermsAndConditionsService } from "./services/TermsAndConditionsService";
import TermsAndConditions from "./components/pages/TermsAndConditions/TermsAndConditions";
import { AuthService } from "./services/AuthService";
import LoadingPage from "./components/pages/LoadingPage/LoadingPage";
import Cookies from "js-cookie";
import {
  COOKIE_NAME_REFRESH_TOKEN,
  COOKIES_EXPIRATION_DELAY,
} from "./config/cookies";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "./contexts/UserInfoContext";

function App() {
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(true);
  const [terms, setTerms] = useState<string | null>(null);

  const userInfo = useUserInfoContext();
  const setUserInfo = useSetUserInfoContext();

  useEffect(() => {
    const codeMatch = window.location.href.match("[?#&]code=([^&]*)");
    const errorMatch = window.location.href.match(
      "User%20does%20not%20have%20appropriate%20group%20memberships",
    );

    const setState = async () => {
      setLoading(true);

      if (codeMatch?.[1]) {
        const authorize = async () => {
          const pingIdJson = await AuthService.getAccessToken(codeMatch[1]);
          Cookies.set(COOKIE_NAME_REFRESH_TOKEN, pingIdJson.refreshToken, {
            expires: new Date().getTime() + COOKIES_EXPIRATION_DELAY,
          });

          setUserInfo(pingIdJson);
          setLoading(false);

          if (pingIdJson.accessToken) {
            const result = await TermsAndConditionsService.getTerms(
              pingIdJson.accessToken,
            );

            setTermsAccepted(result.accepted);
            setTerms(result.terms);
          }

          setLoading(false);
        };

        authorize();
      } else {
        const refresh_token = Cookies.get(COOKIE_NAME_REFRESH_TOKEN);

        if (refresh_token && refresh_token !== "undefined") {
          // so this is a little confusing. Instead of refreshing the token when the page is refreshed we are
          // just updating the user object, that way we don't have to worry about the state of tokens being
          // controlled by multiple tabs, the state of one window won't conflict with other opened sessions,
          // and it will save on initial load time
          setUserInfo({
            accessToken: "",
            refreshToken: refresh_token,
            expiresAt: 0,
            idToken: "",
          });
        }

        // Note: The loading at DEV mode is not displaying because useEffect is fired twice at dev mode.
        //       This line below is fired at second run and preventing displaying Loading page.
        setLoading(false);
      }

      window.history.replaceState({}, "", window.location.pathname);
    };

    if (errorMatch) {
      alert("user does not have access to the appropriate group");
      window.history.replaceState({}, "", window.location.pathname);
    } else {
      setState();
    }
  }, [setUserInfo, setLoading]);

  if (loading) {
    return <LoadingPage />;
  }

  if (!userInfo) {
    return <LandingPage />;
  }

  if (!termsAccepted) {
    return (
      <TermsAndConditions setTermsAccepted={setTermsAccepted} terms={terms} />
    );
  }

  return <ChatUi />;
}

export default App;
