import { ChatMessageAssistant } from "../types";
import ReactMarkdown from "react-markdown";
import ChatGPCLogomark from "./ChatGPCLogomark";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@bolt/components";

import "./ChatAssistantResponseMessageStyles.css";
import ChatAssistantResponseChartTab from "./ChatAssistantResponseChartTab";
import ChatAssistantResponseDetailsTab from "./ChatAssistantResponseDetailsTab";
import ChatAssistantResponseDatasetTab from "./ChatAssistantResponseDatasetTab";
import { memo } from "react";
import { ChatTheme } from "../hooks/useTheme";
import { uniq } from "lodash";

type ChatAssistantResponseMessageProps = {
  message: ChatMessageAssistant;
  theme: ChatTheme;
};

const ChatAssistantResponseMessage = ({
  message,
  theme,
}: ChatAssistantResponseMessageProps) => {
  const defaultActiveTab = message?.frame?.data ? "visualization" : "summary";

  const isBarChartFn = () => {
    const axisX =
      message.frame?.schema?.fields?.find((field) =>
        field.name.includes("_DATE"),
      )?.name || "";
    if (axisX) {
      const dates = message.frame?.data.map((row) => row[axisX]);
      if (uniq(dates).length === 1) {
        return true;
      }
    }

    return (
      JSON.stringify(message.details?.parameters?.date_to) ===
      JSON.stringify(message.details?.parameters.date_from)
    );
  };

  return (
    <div className="message bot-message">
      <ChatGPCLogomark className="chat-img" />
      <Tabs
        defaultValue={defaultActiveTab}
        variant="with-rule-ghost"
        className="answer-tabs"
      >
        <TabsList>
          {message.text && <TabsTrigger value="summary">Summary</TabsTrigger>}

          {message.frame?.data && (
            <TabsTrigger value="visualization">Visualization</TabsTrigger>
          )}

          {message.frame?.data && (
            <TabsTrigger value="data">Data Source</TabsTrigger>
          )}

          {message.details?.metric_name &&
            message.details.metric_name !== "about" && (
              <TabsTrigger value="details">Details</TabsTrigger>
            )}
          {false && <TabsTrigger value="reasoning">Reasoning</TabsTrigger>}
        </TabsList>

        {message.text && (
          <TabsContent value="summary">
            <ReactMarkdown>{message.text}</ReactMarkdown>
          </TabsContent>
        )}

        {message.details && (
          <TabsContent value="details">
            <ChatAssistantResponseDetailsTab details={message.details} />
          </TabsContent>
        )}
        {message?.frame?.data && (
          <TabsContent value="visualization" className="overflow-x-auto">
            <ChatAssistantResponseChartTab
              isBarChart={isBarChartFn()}
              message={message}
              metricName={message.details?.metric_name || ""}
              theme={theme}
            />
          </TabsContent>
        )}
        {message?.frame?.data && (
          <TabsContent value="data" className="overflow-x-auto">
            <ChatAssistantResponseDatasetTab frame={message.frame} />
          </TabsContent>
        )}
      </Tabs>
    </div>
  );
};

export default memo(
  ChatAssistantResponseMessage,
  (prev, next) => prev.message.questionId === next.message.questionId,
);
