import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";

import "./GuidancePageContentStyles.css";
import useGenerativeAIGuidance from "../../../hooks/useGenerativeAIGuidance";
type GuidanceInfoContentProps = { terms: string | null };
const GuidanceInfoContent = ({ terms }: GuidanceInfoContentProps) => {
  const text = useGenerativeAIGuidance();

  useEffect(() => {
    // Note: Error notification
    if (!terms) {
      console.warn(
        "The Terms are not present at endpoint /terms. Taken from the file.",
      );
    }
  }, [terms]);

  return (
    <div className="text-container">
      <ReactMarkdown>{terms ? terms : text}</ReactMarkdown>
    </div>
  );
};

export default GuidanceInfoContent;
