type TermsResponse = {
  accepted: boolean;
  terms: string | null;
};
export class TermsAndConditionsService {
  static async getTerms(accessToken: string): Promise<TermsResponse> {
    const TERMS_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v1/terms";

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      mode: "cors" as RequestMode,
    };

    return await fetch(TERMS_ENDPOINT, requestOptions)
      .then((response) => response.json())
      .catch((error) => {
        if (error.message) {
          return { accepted: null, terms: "this is an error fake response if" };
        } else {
          return {
            accepted: null,
            terms: "this is an error fake response else",
          };
        }
      });
  }

  static async acceptTerms(accessToken: string): Promise<any> {
    const TERMS_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v1/terms";

    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      mode: "cors" as RequestMode,
      body: JSON.stringify({
        accepted: true,
      }),
    };

    await fetch(TERMS_ENDPOINT, requestOptions)
      .then(async (response) => console.log(await response.json()))
      .catch((error) => {
        console.error("oops did not work", error);
      });

    return true;
  }
}
