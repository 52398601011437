import { Heading } from "@bolt/components";
import ChatGPCLogomark from "./ChatGPCLogomark";

import "./ChatGPCLogoStyles.css";
import clsx from "clsx";
import { memo } from "react";

type ChatGPCLogoProps = {
  className?: string;
};

const ChatGPCLogo = (props: ChatGPCLogoProps) => (
  <a
    className="gpc-logo"
    href="/"
    onClick={(e) => {
      window.location.pathname === "/" && e.preventDefault();
    }}
  >
    <ChatGPCLogomark className={clsx("logo", props.className)} />
    <Heading variant="lg" level="h2">
      ChatWithYourData
    </Heading>
  </a>
);

export default memo(ChatGPCLogo);
