function arrayOfArrayToCSV(data: Array<Array<number | string>>) {
  return data
    .map(function (d) {
      return JSON.stringify(d);
    })
    .join("\n")
    .replace(/(^\[)|(\]$)/gm, ""); // remove opening [ and closing ] brackets from each line
}

export default arrayOfArrayToCSV;
