import "./ChatFooterDisclosureStyles.css";

const ChatFooterDisclosure = () => (
  <p className="chat-footer-disclosure">
    ChatWithYourData can make mistakes or display inaccurate information.
    Consider double-checking important information.
  </p>
);

export default ChatFooterDisclosure;
