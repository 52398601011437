import "./ChatErrorMessageStyles.css";
import { MdWarning } from "react-icons/md";

type ChatErrorMessageProps = {
  errorMessage: string;
};

// TODO: Bolt Alert component might be used here
const ChatErrorMessage = ({ errorMessage }: ChatErrorMessageProps) => (
  <div className="chat-error-message">
    <MdWarning size={25} className="warning-icon" />
    <p>{errorMessage}</p>
  </div>
);

export default ChatErrorMessage;
