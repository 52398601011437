import Plot from "react-plotly.js";
import { ChatMessageAssistantParts } from "../types";
import groupBy from "lodash/groupBy";
import { Data, Layout } from "plotly.js";

import "./ChatAssistantResponseChartTab.css";
import { ChatTheme } from "../hooks/useTheme";
import { sortBy, uniq } from "lodash";

type ChatAssistantResponseChartTabProps = {
  message: ChatMessageAssistantParts;
  metricName: string;
  theme: ChatTheme;
  isBarChart: boolean;
};

const DARK_LAYOUT = {
  plot_bgcolor: "#03001f",
  paper_bgcolor: "#03001f",
  font: { color: "#DDD" },
};

const LIGHT_LAYOUT = {
  plot_bgcolor: "white",
  paper_bgcolor: "white",
  font: { color: "#03001f" },
};

const LAYOUT_BAR_CHART: Partial<Layout> = {
  showlegend: false,
  margin: { l: 150 },
};

const LAYOUT_LINES_CHART = {
  showlegend: true,
};

const ChatAssistantResponseChartTab = ({
  message,
  metricName,
  isBarChart,
  theme,
}: ChatAssistantResponseChartTabProps) => {
  const layoutTheme = theme === "dark" ? DARK_LAYOUT : LIGHT_LAYOUT;

  const dcColumn = "DC__DC_NAME_HIST";
  const layoutChartTypeBased = isBarChart
    ? LAYOUT_BAR_CHART
    : LAYOUT_LINES_CHART;
  const dataRowColumn =
    message.frame?.schema?.fields.find((field) =>
      field.name.toUpperCase().includes(metricName.toUpperCase()),
    )?.name ||
    message.frame?.schema?.fields.find((field) =>
      field.type === "number" && field.name.toUpperCase() !== "DIFFERENCE"
    )?.name ||
    "";

  const dataRowColumnToDisplay = dataRowColumn
    .replace("DC__", "")
    .replaceAll("_", " ");

  function getDataBasedOnChartType(isBarChart: boolean): Data[] {
    if (isBarChart) {
      const dataSorted = sortBy(message.frame?.data, dataRowColumn).reverse();
      const dataGrouped: Data = {
        x: dataSorted.map((dataRow) => dataRow[dataRowColumn]),
        y: dataSorted.map((dataRow) => dataRow[dcColumn]),
        type: "bar",
        orientation: "h",
        hoverinfo: "x+y",
      };

      return [
        dataGrouped,
        message.details?.parameters?.metric_threshold
          ? {
              y: message.frame?.data.map((dataRow) => dataRow[dcColumn]),
              x: message.frame?.data.map(
                () => message?.details?.parameters?.metric_threshold!,
              ),
              name: "threshold",
              mode: "lines",
              line: { dash: "dash" },
              hoverinfo: "x+y",
            }
          : {},
      ];
    } else {
      const axisX =
        message.frame?.schema?.fields?.find((field) =>
          field.name.includes("_DATE"),
        )?.name || "";

      const dataGrouped = Object.values(
        groupBy(message.frame?.data || [], dcColumn),
      ).map(
        (row): Data => ({
          x: row.map((dataRow) => new Date(dataRow[axisX])),
          y: row.map((dataRow) => dataRow[dataRowColumn]),
          type: "scatter",
          mode: "lines",
          name: row[0][dcColumn],
        }),
      );

      if (message.details?.parameters?.metric_threshold) {
        dataGrouped.push({
          x: message.frame?.data.map((dataRow) => new Date(dataRow[axisX])),
          y: message.frame?.data.map(
            () => message?.details?.parameters?.metric_threshold!,
          ),
          name: "threshold",
          mode: "lines",
          line: { dash: "dash" },
        });
      }

      return dataGrouped;
    }
  }

  const plotlyData = getDataBasedOnChartType(isBarChart);

  function getHeightBasedOnChartType(isBarChart: boolean): number {
    if (isBarChart) {
      // @ts-ignore: field exists
      if (plotlyData[0]?.y) {
        // @ts-ignore: field exists
        const countYValues = uniq(plotlyData[0].y).length || 50;

        return countYValues * 16 + 166;
      }

      return 900;
    }
    return 500;
  }

  return (
    <div className="plotly-container">
      <Plot
        data={plotlyData}
        layout={{
          title: dataRowColumnToDisplay,
          width: 900,
          yaxis: { rangemode: "tozero" },
          ...layoutChartTypeBased,
          ...layoutTheme,
          height: getHeightBasedOnChartType(isBarChart),
        }}
      />
    </div>
  );
};

export default ChatAssistantResponseChartTab;
