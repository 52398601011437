import {
  Icon,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@bolt/components";
import { ChatMessagePartsFrame } from "../types";
import DownloadCSVButton from "./DownloadCSVButton";
import { memo, useState } from "react";

import "./ChatAssistantResponseDatasetTabStyles.css";
import DatasetTableCell from "./DatasetTableCell";

type ChatAssistantResponseDatasetTabProps = {
  frame: ChatMessagePartsFrame;
};

const CSV_FILENAME = "CSV_ChatWithData-data";

function mapPropsToCSVData(frame: ChatMessagePartsFrame) {
  const data = [frame?.schema?.fields.map((field) => field.name)];

  frame?.data.forEach((dataRow) => {
    data.push(Object.keys(dataRow).map((keyName: string) => dataRow[keyName]));
  });

  return data;
}

const ChatAssistantResponseDatasetTab = ({
  frame,
}: ChatAssistantResponseDatasetTabProps) => {
  const [orderBy, setOrderBy] = useState(
    frame?.schema?.fields?.[0].name || "index",
  );
  const [orderDirection, setOrderDirection] = useState(1);

  const dataCSV = mapPropsToCSVData(frame);

  const tableHeaders = frame?.schema?.fields || [];
  const tableDataSorted = [...frame.data].sort((dataRow1, dataRow2) =>
    dataRow1[orderBy] < dataRow2[orderBy]
      ? -1 * orderDirection
      : 1 * orderDirection,
  );

  return (
    <>
      <div className="btn-wrapper-csv">
        <DownloadCSVButton
          fileName={CSV_FILENAME}
          data={dataCSV}
          className="btn-download-csv"
        />
      </div>
      <div className="dataset-table-wrapper">
        <Table density="default" variant="line" className="table-dataset">
          <TableHeader>
            <TableRow>
              {tableHeaders.map((tableField) => (
                <TableHead
                  className="table-header-cell"
                  key={tableField.name + "-" + tableField.type}
                  onClick={() => {
                    setOrderBy(tableField.name);

                    if (orderBy === tableField.name && orderDirection === 1) {
                      setOrderDirection(-1);
                    } else {
                      setOrderDirection(1);
                    }
                  }}
                >
                  {tableField.name}
                  {tableField.name === orderBy && (
                    <Icon
                      className="table-header-cell-arrow"
                      icon={orderDirection === 1 ? "arrow-down" : "arrow-up"}
                    />
                  )}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableDataSorted.map((dataRow, index) => (
              <TableRow key={index}>
                {Object.keys(dataRow).map((keyName: string) => (
                  <DatasetTableCell
                    key={keyName}
                    value={dataRow[keyName]}
                    columnName={keyName}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default memo(ChatAssistantResponseDatasetTab);
