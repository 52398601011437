export type displayLanguage = {
  en: displayConstants;
};

type displayConstants = {
  appHeader: string;
  appSubHeader: string;
  login: string;
  sidebarHeader: string;
  generalChatBotPrompt: string;
  accept: string;
  decline: string;
  disclosure: string;
};

export const display: displayLanguage = {
  en: {
    appHeader: "ChatWithYourData",
    appSubHeader: "Your Personal AI Assistant",
    login: "Log in",
    sidebarHeader: "ChatWithYourData",
    generalChatBotPrompt: `Hello, I'm a chatbot assistant. Ask me a question...`,
    accept: "Accept",
    decline: "Decline",
    disclosure:
      "Disclosure Notice! Do not input personal information into this tool. All usage of ChatWithYourData will be monitored and logged. Employees are expected to follow GPC policies and procedures as related to usage of ChatWithYourData. Always verify the accuracy and appropriateness of any outputs from ChatWithYourData.",
  },
};

// export default display;
