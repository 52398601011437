import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextareaAutosize } from "@mui/material";
import { Button, IconButton, Switch } from "@bolt/components";
import { ChatGpcService } from "../../../services/ChatGpcService";
import { display } from "../../../utils/displayValues";
import { ChatMessage, ChatMessageUser, ChatRequest } from "../../../types";
import "./ChatUiStyles.css";
import { AuthService } from "../../../services/AuthService";
import ChatMessageComponent from "../../ChatMessageComponent";
import MessageBot from "../../MessageBot";
import SkeletonTextBlock from "../../SkeletonTextBlock";
import ChatGPCLogo from "../../ChatGPCLogo";
import LogOutButton from "../../LogOutButton";
import useTheme from "../../../hooks/useTheme";
import DisclosureWarning from "../../DisclosureWarning";
import ChatFooterDisclosure from "../../ChatFooterDisclosure";
import Cookies from "js-cookie";
import {
  COOKIE_NAME_REFRESH_TOKEN,
  COOKIES_EXPIRATION_DELAY,
} from "../../../config/cookies";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "../../../contexts/UserInfoContext";
import { DetailsService } from "../../../services/DetailsSetvice";
import ExampleQuesionTiles from "../../ExampleQuestionTiles";

const ON_BUTTON_ABOUT_CLICKED_MESSAGE = "What are your capabilities?";

const ChatUi = () => {
  const setUserInfo = useSetUserInfoContext();
  const userInfo = useUserInfoContext()!;

  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [responding, setResponding] = useState<boolean>(false);
  const [userQuestion, setUserQuestion] = useState<string>("");
  const [chatId, setChatId] = useState<string>();
  const msgEnd = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const focusOnInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const { theme, toggleTheme } = useTheme();

  // Note: auto-focus initially and after getting the response
  useEffect(() => {
    if (responding === false) {
      focusOnInput();
    }
  }, [responding]);

  useEffect(() => {
    // Note: The setTmeout was added to wait for plotly to be initiated.
    //       The auto-scroll doesn't work without it for visualization tab.
    //       It might be refactored and some callback might be used at plotly
    setTimeout(() => {
      msgEnd?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 50);
  }, [chatHistory]);

  useEffect(() => {
    if (userInfo.accessToken) {
      DetailsService.fetchDetails(userInfo.accessToken);
    }
    // eslint-disable-next-line
  }, [userInfo.accessToken]);

  const resetChat = useCallback(async () => {
    await handleAbort();
    setChatHistory([]);
    setChatId(undefined);
  }, [setChatHistory]);

  const handleSend = async () => {
    sendMessage(userQuestion);
  };

  const sendMessage = async (message: string) => {
    const messageTrimmed = message.replace(/^\s+|\s+$/g, "");
    setUserQuestion("");
    setResponding(true);
    setChatHistory((state) => [
      ...state,
      { content: messageTrimmed, role: "user", error: false },
    ]);

    let { accessToken } = userInfo;
    if (userInfo.expiresAt < Date.now()) {
      // TODO: the exceptions should be handled at refreshToken fetch
      try {
        const refreshResult = await AuthService.refreshToken(
          userInfo.refreshToken,
        );

        Cookies.set(COOKIE_NAME_REFRESH_TOKEN, refreshResult.refreshToken, {
          expires: new Date().getTime() + COOKIES_EXPIRATION_DELAY,
        });

        accessToken = refreshResult.accessToken;
        setUserInfo(refreshResult);
      } catch (e: any) {
        console.error("Unable to refresh the token", e);
        setUserInfo(null);
      }
    }
    if (messageTrimmed === ON_BUTTON_ABOUT_CLICKED_MESSAGE) {
      const infoAbout = await DetailsService.getAbout(userInfo.accessToken);

      setChatHistory((state) => [
        ...state,
        { role: "assistant", ...infoAbout },
      ]);
    } else {
      const requestBody: ChatRequest = {
        message: messageTrimmed,
      };

      const result: ChatMessage = await ChatGpcService.askQuestion(
        requestBody,
        accessToken,
        () => {
          setUserInfo(null);
        },
        setChatId,
        chatId,
      );

      setChatHistory((state) => [...state, result]);
    }

    setResponding(false);
  };

  const handleAbort = async () => {
    await ChatGpcService.abortQuestion();
    setResponding(false);
  };

  const handleRegenerateResponse = () => {
    const userQuestion =
      (
        chatHistory
          .slice()
          .reverse()
          .find(
            (message: ChatMessage) => message.role === "user",
          ) as ChatMessageUser
      )?.content || "";

    if (userQuestion) {
      sendMessage(userQuestion);
    } else {
      console.error("The user question from chatHistory is empty!");
    }
  };

  const onKeyDownTextarea = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      if (userQuestion !== "") {
        handleSend();
      }
      e.preventDefault();
    }
  };

  const requestAbout = async () => {
    sendMessage(ON_BUTTON_ABOUT_CLICKED_MESSAGE);
  };

  return (
    <div className="theme-container" data-theme={theme}>
      <div className={"chat-screen-container"}>
        <div className="sidebar">
          <ChatGPCLogo className="chat-img" />
          <div className="sidebar-actions-center">
            <Button
              variant="outline"
              size="lg"
              data-testid="clear-history-btn"
              onClick={resetChat}
            >
              Reset chat
            </Button>
            <Button size="lg" onClick={requestAbout} variant="outline">
              About
            </Button>
          </div>

          <div className="sidebar-actions-bottom">
            <Switch
              data-testid="dark-mode-toggle"
              onClick={toggleTheme}
              label="dark mode"
              size="md"
              checked={theme === "dark"}
            />
            <LogOutButton />
          </div>
        </div>
        <div className="main">
          <div className="chat-header">
            <DisclosureWarning />
          </div>
          <div className="chats">
            <MessageBot>
              <div>
                <p>{display.en.generalChatBotPrompt}</p>
                {!chatHistory.length && (
                  <ExampleQuesionTiles sendMessage={sendMessage} />
                )}
              </div>
            </MessageBot>
            {chatHistory.map((message: ChatMessage, index: number) => (
              <ChatMessageComponent
                theme={theme}
                key={
                  index +
                  "_" +
                  message.role +
                  "" +
                  message.content?.substring(0, 20)
                }
                message={message}
                handleRegenerateResponse={handleRegenerateResponse}
                isLatest={index === chatHistory.length - 1}
              />
            ))}
            {responding && (
              <MessageBot>
                <div style={{ width: "100%" }}>
                  <SkeletonTextBlock />
                </div>
              </MessageBot>
            )}
            <div ref={msgEnd} />
          </div>
          <div className="input-box-container">
            <div className="chat-text-area">
              <TextareaAutosize
                ref={inputRef}
                maxRows={7}
                data-testid="chat-input"
                draggable={false}
                disabled={responding}
                placeholder="Ask me a question"
                onKeyDown={onKeyDownTextarea}
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  setUserQuestion(e.currentTarget.value)
                }
                value={userQuestion}
              />
            </div>
            {responding ? (
              <IconButton
                ariaLabel="Stop generating response"
                onClick={handleAbort}
                className="abort-button"
                data-testid="abort-button"
                size="md"
                iconName="xmark-large"
                variant="outline"
                // Note: This is commented because it doesn't work as expected at current version of BOLT package
                // The override has been added at css file
                // intent="destructive"
              />
            ) : (
              <IconButton
                ariaLabel="Send"
                disabled={userQuestion === ""}
                className="send-button"
                data-testid="send-button"
                onClick={handleSend}
                size="md"
                iconName="chevron-right"
                variant="outline"
              />
            )}
          </div>
          <ChatFooterDisclosure />
        </div>
      </div>
    </div>
  );
};

export default ChatUi;
