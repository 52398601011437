import React, { ButtonHTMLAttributes, memo } from "react";
import arrayOfArrayToCSV from "../utils/arrayOfArrayToCSV";
import { Button } from "@bolt/components";

type DownloadCSVProps = {
  fileName: string;
  data: Array<Array<string | number>>;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const DownloadCSVButton = ({
  data,
  fileName,
  ...restProps
}: DownloadCSVProps) => {
  const downloadCSV = () => {
    const csvData = new Blob([arrayOfArrayToCSV(data)], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      size="lg"
      variant="outline"
      iconLeft="down-to-bracket"
      onClick={downloadCSV}
      {...restProps}
    >
      Download CSV
    </Button>
  );
};

export default memo(DownloadCSVButton);
