import { ChatMessage } from "../types";
import { MdOutlineFaceUnlock } from "react-icons/md";
import "./ChatMessageComponentStyles.css";
import ChatErrorMessage from "./ChatErrorMessage";
import { Button } from "@bolt/components";
import ChatAssistantResponseMessage from "./ChatAssistantResponseMessage";
import { ChatTheme } from "../hooks/useTheme";

type ChatMessageComponentProps = {
  message: ChatMessage;
  isLatest?: boolean;
  handleRegenerateResponse: () => void;
  theme: ChatTheme;
};

const ChatMessageComponent = ({
  message,
  isLatest = false,
  handleRegenerateResponse,
  theme,
}: ChatMessageComponentProps) => {
  if (message?.error) {
    return (
      <>
        <ChatErrorMessage errorMessage={message.content!} />
        {isLatest && (
          <div className="chat-action-button-wrapper">
            <Button
              onClick={handleRegenerateResponse}
              className="regenerate-response-chat-button"
              size="lg"
              variant="solid"
              intent="primary"
            >
              Regenerate your question
            </Button>
          </div>
        )}
      </>
    );
  }

  if (message.role === "assistant") {
    return <ChatAssistantResponseMessage message={message} theme={theme} />;
  }

  return (
    <div className="message">
      <div>
        <MdOutlineFaceUnlock className="chat-img" size="100%" />
      </div>
      <p className="chat-markdown">{message.content}</p>
    </div>
  );
};

export default ChatMessageComponent;
